import { SecureClient } from 'core.ui-module_site-security';

export const getHelpQtip = (fieldName) =>
  new Promise((resolve, reject) => {
    const secureClient = new SecureClient();
    secureClient
      .get('/application/consultant/content/by-type-and-site', {
        headers: {
          siteAreaId: 500000,
          contentType: 'help_qtip',
          fieldName,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getFundDisclosure = (fieldName) =>
  new Promise((resolve, reject) => {
    const secureClient = new SecureClient();
    secureClient
      .get('/application/consultant/content/by-type-and-site', {
        headers: {
          siteAreaId: 500000,
          contentType: 'fundLibrary_disclosure',
          fieldName,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getReportTooltip = () =>
  new Promise((resolve, reject) => {
    const secureClient = new SecureClient();
    secureClient
      .get('/application/consultant/content/by-type-and-site', {
        headers: {
          siteAreaId: 500000,
          contentType: 'scheduleReport_qtip',
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getReportTypeTooltip = () =>
  new Promise((resolve, reject) => {
    const secureClient = new SecureClient();
    secureClient
      .get('/application/consultant/content/by-type-and-site', {
        headers: {
          siteAreaId: 500000,
          contentType: 'ca_report_help',
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getScheduleReportMessage = () =>
  new Promise((resolve, reject) => {
    const secureClient = new SecureClient();
    secureClient
      .get('/application/consultant/content/by-type-and-site', {
        headers: {
          siteAreaId: 500000,
          contentType: 'message',
          fieldName: 'report',
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getDocumentArchiveMessage = () =>
  new Promise((resolve, reject) => {
    const secureClient = new SecureClient();
    secureClient
      .get('/application/consultant/content/by-type-and-site', {
        headers: {
          siteAreaId: 500000,
          contentType: 'message',
          fieldName: 'docs',
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const getContactUsMessage = () =>
  new Promise((resolve, reject) => {
    const secureClient = new SecureClient();
    secureClient
      .get('/application/consultant/content/by-type-and-site', {
        headers: {
          siteAreaId: 500000,
          contentType: 'ca_contactUs',
          fieldName: 'contactUs',
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
