import React, { Component } from 'react';
import { SecureApp } from 'core.ui-module_site-security';
import PageLoader from './components/PageLoader';
import site from '../json/site.json';
import ContactUs from './layout/header/ContactUs';
import ProfileInformation from './layout/header/ProfileInformation';
import HeaderLinks from './layout/header/HeaderLinks';
import CustomMessageResolver from './api/utility/CustomMessageResolver';
import logo from 'core.ui-module_site-styling/lib/images/logos/site-logo-reverse.svg';

export default class App extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="site">
        <SecureApp
          customMessageResolver={CustomMessageResolver}
          loader={PageLoader}
          site={site}
          contactUs={<ContactUs />}
          profileInformation={<ProfileInformation />}
          headerLinks={<HeaderLinks site={site} />}
          logoutSuccessPath="/dcio/home.html"
          logo={logo}
          slimView={false}
        />
      </div>
      
    );
  }
}

