import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import dompurify from 'dompurify';
import { Modal, Spinner } from 'core.ui-module_site-components';
import { SecurityContextHolder } from 'core.ui-module_site-security';
import { getContactUsMessage } from '../../api/utility/help';

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: false,
      isLoading: true,
      contactUsMessage: [],
    };
  }

  toggleShow(isShow) {
    this.setState({ isShow });
    let pMessage = {};
    const promise1 = getContactUsMessage().then((data) => {
      pMessage = data;
    });

    Promise.all([promise1])
      .then(() => {
        this.setState({
          contactUsMessage: pMessage,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isError: true,
        });
      });
  }

  render() {
    const { isShow, contactUsMessage, isLoading } = this.state;
    const external = !SecurityContextHolder.isAuthenticated();
    return (
      <Fragment>
        <a
          onClick={() => {
            this.toggleShow(true);
          }}
          className="navbar-item contact-us"
        >
          Contact Us
        </a>
        {isShow && (
          <Modal
            header="Contact Us"
            footer={<div />}
            closeModalCallback={() => {
              this.toggleShow(false);
            }}
          >
            {isLoading ? (
              <div className="is-full-width has-text-centered">
                <Spinner />
              </div>
            ) : (
              <div>
                {contactUsMessage?.map((item, index) => (
                  <div key={`message_secion_${item.title}_${index}`}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: dompurify.sanitize(item.textLong, {
                          USE_PROFILES: { html: true },
                        }),
                      }}
                    />
                  </div>
                ))}
                {external && (
                  <Fragment>
                    <h4>Consultant Access Portal</h4>
                    <p>Logon to Consultant Access to:</p>
                    <ul>
                      <li>
                        View plan information across your clients serviced by MissionSquare
                        Retirement.
                      </li>
                      <li>
                        Run and download client reporting including enrollment activity, site visits
                        and transaction activity.
                      </li>
                      <li>View client fund performance, benchmarks and related data points.</li>
                      <li>
                        Access information on over 15,000 funds available on MissionSquare
                        Retirement&apos;s record keeping platform.
                      </li>
                    </ul>
                  </Fragment>
                )}
              </div>
            )}
          </Modal>
        )}
      </Fragment>
    );
  }
}
ContactUs.propTypes = {
  external: PropTypes.bool,
};

ContactUs.defaultProps = {
  external: undefined,
};
