import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ErrorBoundry } from 'core.ui-module_site-components';

const PageLoader = (componentPath, menuItem, props) => {
  const PageComponent = React.lazy(
    () =>
      import(
        /* webpackChunkName: "pages" */
        /* webpackMode: "lazy" */
        /* webpackExclude: /\.test\.js$/ */
        `../pages${componentPath}`
      )
  );

  return (
    <ErrorBoundry>
      <React.Suspense fallback={<>Loading next...</>} key={uuidv4()}>
        <PageComponent {...props} />
      </React.Suspense>
    </ErrorBoundry>
  );
};

export default PageLoader;
