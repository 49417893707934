import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ApplicationContext } from 'core.ui-module_site-security';
import { Spinner } from 'core.ui-module_site-components';

export const store = (key, data) => {
  ApplicationContext.setContext(`${key}`, JSON.stringify(data));
};

export default class ProfileInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      profileInfo: [],
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.setState({     
      profileInfo : JSON.parse(ApplicationContext.getContext('ConsultantProfile')),
      isLoading : false,
    });
    
  }

  render() {
    const { profileInfo, isLoading } = this.state;
    if (isLoading) {
      return (
        <div className="is-full-width has-text-centered">
          <Spinner />
        </div>
      );
    }
    return (
      <Fragment>
        <div className="box with-border">
          <div className="profile">
            <p className="title is-3">Address and Personal Information</p>
            <div className="columns is-gapless is-desktop is-tablet">
              <div className="column is-6">
                <div className="columns">
                  <div className="column is-3">
                    <span className="label">
                      <b>Name: </b>
                    </span>
                  </div>
                  <div className="column is-8">
                    {profileInfo.firstName} {profileInfo.lastName}
                  </div>
                </div>
              </div>
              <div className="column is-6">
                <div className="columns">
                  <div className="column is-3">
                    <span className="label">
                      <b>Title: </b>
                    </span>
                  </div>
                  <div className="column is-8">{profileInfo.title}</div>
                </div>
              </div>
            </div>

            <div className="columns is-gapless is-desktop is-tablet">
              <div className="column is-6">
                <div className="columns">
                  <div className="column is-3">
                    <span className="label">
                      <b>Company: </b>
                    </span>
                  </div>
                  <div className="column is-8">{profileInfo.companyName}</div>
                </div>
              </div>

              <div className="column is-6">
                <div className="columns">
                  <div className="column is-3">
                    <span className="label">
                      <b>Company Address: </b>
                    </span>
                  </div>
                  <div className="column is-8">
                    {profileInfo.city}, {profileInfo.state} {profileInfo.zip}
                  </div>
                </div>
              </div>
            </div>

            <div className="columns is-gapless is-desktop is-tablet">
              <div className="column is-6">
                <div className="columns">
                  <div className="column is-3">
                    <span className="label">
                      <b>Email Address: </b>
                    </span>
                  </div>
                  <div className="column is-8">{profileInfo.emailAddress}</div>
                </div>
              </div>
              <div className="column is-6">
                <div className="columns">
                  <div className="column is-3">
                    <span className="label">
                      <b>&nbsp; </b>
                    </span>
                  </div>
                  <div className="column is-8">&nbsp;</div>
                </div>
              </div>
            </div>

            <div className="columns is-gapless is-desktop is-tablet">
              <div className="column is-6">
                <div className="columns">
                  <div className="column is-3">
                    <span className="label">
                      <b>Business Phone: </b>
                    </span>
                  </div>
                  <div className="column is-8">{profileInfo.businessPhone}</div>
                </div>
              </div>

              <div className="column is-6">
                <div className="columns">
                  <div className="column is-3">
                    <span className="label">
                      <b>Mobile Phone: </b>
                    </span>
                  </div>
                  <div className="column is-8">{profileInfo.mobilePhone}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

ProfileInformation.propTypes = {
  profileInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    title: PropTypes.string,
    companyName: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string,
    businessPhone: PropTypes.string,
    mobilePhone: PropTypes.string,
    emailAddress: PropTypes.string,
  }),
  
};
