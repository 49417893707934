// eslint-disable-next-line import/prefer-default-export
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const CustomMessageResolver = ({ statusCode, message, context }) => {
  if (
    context === 'reset-account' &&
    (message === 'Invalid token context associated with the specified token' ||
      statusCode === '403' ||
      statusCode === '401')
  ) {
    return (
      <>
        {/* <p>The link has expired, please request another. Or you may also setup your access by <NavLink to="/forgot.html"><b>Clicking Here</b></NavLink>.
        </p> */}
        <p>The link has expired, <NavLink to="/forgot.html"><b>Click Here</b></NavLink> to setup your access.
        </p>
      </>
    );
  }

  return null;
};

CustomMessageResolver.propTypes = {
  statusCode: PropTypes.string,
  message: PropTypes.string,
  context: PropTypes.string,
};

export default CustomMessageResolver;
