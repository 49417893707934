import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { SecurityContextHolder } from 'core.ui-module_site-security';

const HeaderLinks = ({ site, location }) => {
  const investmentsUrl =
    location.pathname === '/login.html' || location.pathname === '/logout.html'
      ? site?.public?.defaultPath
      : '/logout.html';

  return (
    <Fragment>
      {(location.pathname !== '/logout.html' && (location.pathname === '/login.html' || SecurityContextHolder.isAuthenticated())) && (
        <Link to={investmentsUrl} className="navbar-item login-link button is-info pr-5 my-2 mr-2">
          <span className="material-icons mr-1 is-size-3">account_balance</span>
          <span className="is-size-5 has-text-weight-bold">MSQ Investments</span>
        </Link>
      )}
    </Fragment>
  );
};

HeaderLinks.propTypes = {
  site: PropTypes.shape({
    public: PropTypes.shape({
      defaultPath: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withRouter(HeaderLinks);
